import * as React from 'react'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from 'utils/link-resolver'

const PreviewPage = () => {
	return (
		<div>
			<h1>Loading preview…</h1>
		</div>
	)
}

export default withPrismicPreviewResolver(PreviewPage, [
	{
		repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
		linkResolver,
	},
])
